<template>
    <div class="table-pagination">
        <el-pagination
            align="center"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageConfig.page"
            :page-sizes="[10, 50, 100, 200]"
            :page-size="pageConfig.per_page"
            layout="total, prev, pager, next, sizes"
            :total="page.total">
        </el-pagination>
    </div>
</template>
<script>
export default {
    name: "table pagination",
    props: {
        page: {
            type: Object,
            default: {
                total: 0
            }
        }
    },
    data(){
        return {
            pageConfig: {
                per_page: 10,
                page: 1
            }
        }
    },
    methods: {
        handleSizeChange(val) {
            this.pageConfig.per_page = val;
            this.$emit('updatePage', this.pageConfig);
        },
        handleCurrentChange(val) {
            this.pageConfig.page = val;
            this.$emit('updatePage', this.pageConfig);
        },
    }
}
</script>

<style scoped>
.table-pagination{
    margin-top: 20px;
}
</style>
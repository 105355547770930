<template>
    <div class="issued-certs">
        <el-card shadow="always">
            <el-table 
                :data="tableRecent"
                @row-click="overallClick"
                style="width: 100%">
                <el-table-column
                :label="$t('data.issuedCerts.table.photo')"
                width="80">
                    <template #default="scope">
                    <img :src="scope.row.member.avatar" alt="" class="table-user-photo">
                    </template>
                </el-table-column>
                <el-table-column
                    prop="member.name"
                    :label="$t('data.issuedCerts.table.applicantName')">
                </el-table-column>
                <el-table-column
                    prop="number"
                    :label="$t('data.issuedCerts.table.eCertNumber')">
                </el-table-column>
                <el-table-column
                    prop="topic"
                    :label="$t('data.issuedCerts.table.certTopic')">
                </el-table-column>
                <el-table-column
                    prop="issue_date"
                    :label="$t('data.issuedCerts.table.certIssueDate')">
                </el-table-column>
                <el-table-column
                    prop="branch.name"
                    :label="$t('data.issuedCerts.table.campusBranch')">
                </el-table-column>
                <el-table-column
                    :label="$t('data.issuedCerts.table.status')">
                    <template #default="scope">
                        <p v-if="scope.row.status === 1">{{ $t('data.status.valid') }}</p>
                        <p v-else-if="scope.row.status === 2">{{ $t('data.status.expired') }}</p>
                        <p v-else-if="scope.row.status === 3">{{ $t('data.status.cancel') }}</p>
                        <p v-else-if="scope.row.status === 4">{{ $t('data.status.change') }}</p>
                        <p v-else-if="scope.row.status === 5">{{ $t('data.status.amended') }}</p>
                        <p v-else-if="scope.row.status === 6">{{ $t('data.status.remark') }}</p>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="approved_at"
                    :label="$t('data.issuedCerts.table.approvedTime')">
                </el-table-column>
            </el-table>
            <table-pagination :page="page" @updatePage="updatePage"></table-pagination>
        </el-card>
    </div>    
</template>

<script>
import tablePagination from "@/components/tablePagination/TablePagination"
import { certsList } from '../../api/index'
export default {
    components:{
        tablePagination
    },
    data(){
        return {
            tableRecent: [],
            page: {},
        }
    },
    mounted() {
        this.initPageData();
        
    },
    methods: {
        initPageData(data){
          let apiData = {
            search: this.$route.query.search || ''
          }
          if(data){
            Object.assign(apiData,data)
          }
          certsList({apiData}).then( res => {
              this.tableRecent = res.data;
              this.page = res.meta.page;
          });
        },
        overallClick(row){
            if(row){
                this.$router.push('/ecert/application-detail/'+row.id);
            }
        },
        updatePage(data){
            this.initPageData(data);
        }
    }
}
</script>

<style scoped>

</style>